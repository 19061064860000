require("@lottiefiles/lottie-player");
//import * as LottiePlayer from "@lottiefiles/lottie-player";
import { Link } from "react-scroll";

export default function About() {
    return(
        <section id="about">
      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
            Hi, I'm Pijush. 
            <br className="hidden lg:inline-block" /> I love to code.
          </h1>
          <p className="mb-8 leading-relaxed">
            Think, Code, Debug, Deploy <br/><b>Repeat ?</b>
          </p>
          <div className="flex justify-center">
            <Link
              to="contact"
              spy={true} smooth={true} duration={1000} 
              className="inline-flex text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg">
              Work With Me
            </Link>
            <Link
              to="projects"
              spy={true} smooth={true} duration={1000} 
              className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">
              See My Past Work
            </Link>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
        <lottie-player
        className="object-cover object-center rounded"
        autoplay
        loop
        mode="normal"
        src="https://assets7.lottiefiles.com/packages/lf20_tno6cg2w.json"
        style={{width:"340px",padding:"15px",display:"inline"}}
        ></lottie-player>

        </div>
      </div>
    </section>
    )
}