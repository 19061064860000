export const projects = [
    {
      title: "Coaching Education System",
      subtitle: "LAMP Stack",
      description:
        "Organize ,Manage Notice , Create Exam & post Results, Manage their students , upload notes etc.",
      image: "./sunshine.JPG",
      link: "https://sunshineinstitution.eu.org/",
    },
    {
      title: "Kolkata Metro Card Utility (Closed)",
      subtitle: "React and Express",
      description:
        "Check Metro card balance , Last transit details, Validity of the card",
      image: "./metro.JPG",
      link: "https://metrocard.itspijush.eu.org/",
    },
    
  ];

  export const skills=
     [{name:"JavaScript"},{name:"Node.js"},{name:"PHP"},{name:"Encryption & Security"},{name:".Net Framework"},{name:"C / C++"},{name:"Java"},{name:"Python"},{name:"DBMS"},{name:"Web Scraping"}]

      
  